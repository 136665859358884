import {StoresWidgetID, APP_DEFINITION_ID} from '@wix/wixstores-client-core';
import {
  BooleanStyleParam,
  ColorStyleParam,
  ComponentClientSpecMapEntry,
  EditorSDK,
  FontStyleParam,
  NumericStyleParam,
  StyleParam,
  ColorParam,
  TPAPublicDataValue,
  TPAPublicDataScope,
  StyleParams as EditorStyleParams,
  ComponentRef,
  TPAPublicData,
} from '@wix/platform-editor-sdk';
import {ThankYouPageStyleParams} from '@wix/wixstores-client-core/dist/es/src/style-params';
import {EcomStyleParams} from '@wix/ecom-platform-sdk/dist/es/src/types';
import {mapSourceToTargets} from '../utils/utils';
import {FontStyleParamData, SiteTextPresets} from '@wix/editor-platform-sdk-types';
import {IHttpClient} from '@wix/http-client';

export class StyleParams {
  constructor(
    private readonly sdk: EditorSDK,
    private readonly applicationId: number,
    private readonly httpClient?: IHttpClient
  ) {}

  public async setCheckoutStyleParam(type: StyleParam['type'], key: string, param: {value: any}) {
    const allComponents = await this.getAllApplicationComponents();
    const checkoutComponent = allComponents.find((comp) => comp.widgetId === StoresWidgetID.CHECKOUT);
    if (!checkoutComponent) {
      return;
    }
    const compRef = await this.sdk.document.components.getById('', {id: checkoutComponent.id});

    await this.sdk.tpa.setStyleParams('', {
      compRef,
      styleParams: [{type, key, param}],
    });
  }

  public async setThankYouPageStyleParams(styleParams?: EcomStyleParams) {
    if (!styleParams?.thankYouPage) {
      return;
    }

    const allComponents = await this.getAllApplicationComponents();
    const thankYouPageComponent = this.getComponent(allComponents, StoresWidgetID.THANK_YOU_PAGE);
    if (!thankYouPageComponent) {
      return;
    }
    const compRef = await this.sdk.document.components.getById('', {id: thankYouPageComponent.id});
    const colorStyleParams = mapColorParams(styleParams);
    const booleanStyleParams = mapBooleanParams(styleParams);
    const fontStyleParams = mapFontParams(styleParams);
    const numberStyleParams = mapNumberParams(styleParams);
    const combinedStyleParams = [...colorStyleParams, ...booleanStyleParams, ...fontStyleParams, ...numberStyleParams];

    if (!combinedStyleParams.length) {
      return;
    }

    await this.sdk.tpa.setStyleParams('', {
      compRef,
      styleParams: combinedStyleParams,
    });

    const textParams = mapTextParams(styleParams);
    await Promise.all(
      textParams.map((param) =>
        this.sdk.tpa.data.set('', {
          compRef,
          ...param,
        })
      )
    );
  }

  public async setSideCartStyleParams() {
    await migrateMiniCartSettings({
      getCompRef: (component: ComponentClientSpecMapEntry) =>
        this.sdk.document.components.getById('', {id: component.id}),
      getComponent: async (widgetId: string) => {
        const allComponents = await this.sdk.document.tpa.app.getAllComps('', APP_DEFINITION_ID);
        return this.getComponent(allComponents, widgetId);
      },
      getStyleParams: (compRef: ComponentRef) => this.sdk.tpa.getStyleParams('', {compRef}),
      getSiteTextPresets: (compRef: ComponentRef) => this.sdk.tpa.getSiteTextPresets('', {compRef}),
      setStyleParams: (ref: ComponentRef, styleParams: StyleParam[]) =>
        this.sdk.tpa.setStyleParams('', {compRef: ref, styleParams}),
      getTexts: (compRef: ComponentRef) => this.sdk.tpa.data.getAll('', {compRef}),
      setTexts: async (options: {compRef: ComponentRef; scope: TPAPublicDataScope; key: string; value: string}) => {
        await this.sdk.tpa.data.set('', options);
      },
      getAppSettingsByExternalId: async (externalId: string) => {
        const response = await this.httpClient.get(
          `https://editor.wix.com/_api/wix-ecommerce-storefront-web/api?o=getAppSettings&s=WixStoresWebClient&q=query,getAppSettings($externalId:String!){appSettings(externalId:$externalId){widgetSettings}}&v=%7B%22externalId%22%3A%22${externalId}%22%7D`
        );
        return (response?.data as any)?.data?.appSettings?.widgetSettings;
      },
      isMultilingualEnabled: () => this.sdk.language?.multilingual?.isEnabled(''),
    });
  }

  private getAllApplicationComponents(): Promise<ComponentClientSpecMapEntry[]> {
    return this.sdk.document.tpa.app.getAllCompsByApplicationId('', this.applicationId);
  }

  private getComponent(allComponents: ComponentClientSpecMapEntry[], componentId: string) {
    return allComponents.find((comp) => comp.widgetId === componentId);
  }
}

function getMappedMiniCartColorParams(minCartColors: {[key: string]: ColorParam}) {
  const colorsMap = {
    titleTextColor: ['cartTitleColor', 'cartSubtitleColor'],
    titleBackgroundColor: ['cartHeaderBackgroundColor'],
    bodyTextColor: ['cartItemNameColor', 'cartItemDiscountNameColor', 'cartItemMoreDetailsColor'],
    bodyBackgroundColor: ['cartItemsBackgroundColor'],
    dividerColor: ['cartItemsDividerColor'],
    buttonTextColor: ['goToCartButtonFontColor'],
    buttonBackgroundColor: ['goToCartButtonBackgroundColor'],
    buttonBorderColor: ['goToCartButtonBorderColor'],
  };

  const color11 = 'color_11';
  const color12 = 'color_12';
  const color15 = 'color_15';
  const color18 = 'color_18';

  const aThemeColor = (themeName: string) => ({themeName, value: ''});
  const getMiniCartColor = (minCartColor: ColorParam, defaultColor: string) =>
    minCartColor ?? aThemeColor(defaultColor);

  const miniCartColors = {
    titleTextColor: getMiniCartColor(minCartColors?.miniCart_headerTextColor, color11),
    bodyTextColor: getMiniCartColor(minCartColors?.miniCart_textColor, color15),
    titleBackgroundColor: getMiniCartColor(minCartColors?.miniCart_headerBackground, color15),
    bodyBackgroundColor: getMiniCartColor(minCartColors?.miniCart_background, color11),
    dividerColor: getMiniCartColor(minCartColors?.miniCart_dividersColor, color12),
    buttonTextColor: getMiniCartColor(minCartColors?.cartWidgetButton_textColor, color11),
    buttonBackgroundColor: getMiniCartColor(minCartColors?.cartWidgetButton_backgroundColor, color18),
    buttonBorderColor: getMiniCartColor(minCartColors?.cartWidgetButton_borderColor, color15),
  };

  return mapSourceToTargets(colorsMap, (src, target) => getColorStyleParams(target, miniCartColors[src]));
}

function getMappedMiniCartBooleanParams() {
  const booleansMap = {
    showCartTitle: ['CART_TITLE_VISIBILITY'],
    showNumberOfItemsInCart: ['CART_NUMBER_OF_CART_ITEMS_VISIBILITY'],
    showQuantitySelector: ['CART_ITEM_QUANTITY_COUNTER_VISIBILITY'],
    showImage: ['CART_ITEM_IMAGE_VISIBILITY'],
    showItemDetails: ['CART_ITEM_INFO'],
    showDisclaimer: ['CART_SUMMARY_DISCLAIMER_VISIBILITY'],
    showSecureCheckout: ['CART_SUMMARY_SECURE_BADGE_VISIBILITY'],
    showGoToCheckoutButton: ['CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY'],
    showGoToCartButton: ['CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY'],
  };

  const sideCartSettingsBooleans = {
    showCartTitle: true,
    showNumberOfItemsInCart: true,
    showQuantitySelector: true,
    showImage: true,
    showItemDetails: true,
    showDisclaimer: false,
    showSecureCheckout: false,
    showGoToCheckoutButton: false,
    showGoToCartButton: true,
  };

  return mapSourceToTargets(booleansMap, (src, target) => getBooleanStyleParams(target, sideCartSettingsBooleans[src]));
}

function getMappedMiniCartFontParams(
  miniCartFonts: {
    [key: string]: FontStyleParamData;
  },
  miniCartTextPresets: SiteTextPresets
) {
  const fontsMap = {
    titleTextFontForTitle: ['cartTitleFont'],
    titleTextFontForSubtitle: ['cartSubtitleFont'],
    bodyTextFontForItemName: ['cartItemNameFont'],
    bodyTextFontForDiscountName: ['cartItemDiscountNameFont'],
    bodyTextFontForMoreDetails: ['cartItemMoreDetailsFont'],
    buttonTextFont: ['goToCartButtonFont'],
  };

  const getFontFamily = (preset: string) => {
    return miniCartTextPresets[preset].fontFamily;
  };

  const bodyMFontFamily = getFontFamily('Body-M');
  const bodyLFontFamily = getFontFamily('Body-L');

  const style = {
    bold: false,
    italic: false,
    underline: false,
  };

  const createFontStyle = (family: string, miniCartFontStyle?: any) => ({
    family,
    style,
    fontStyleParam: true,
    ...miniCartFontStyle,
  });

  const customPreset = 'Custom';
  const sideCartOverride = {
    cartTitleFont: {preset: customPreset, size: 23},
    cartSubtitleFont: {preset: customPreset, size: 16},
    cartItemNameFont: {preset: customPreset, size: 16},
    cartItemDiscountNameFont: {preset: customPreset, size: 14},
    cartItemMoreDetailsFont: {preset: customPreset, size: 14},
    goToCartButtonFont: {preset: customPreset, size: miniCartFonts?.cartWidgetButton_textFont?.size ?? 16},
  };

  const fonts = {
    titleTextFontForTitle: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_headerTextFontStyle),
    titleTextFontForSubtitle: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_headerTextFontStyle),
    bodyTextFontForItemName: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_textFontStyle),
    bodyTextFontForDiscountName: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_textFontStyle),
    bodyTextFontForMoreDetails: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_textFontStyle),
    buttonTextFont: createFontStyle(bodyLFontFamily, miniCartFonts?.cartWidgetButton_textFont),
  };

  return mapSourceToTargets(
    fontsMap,
    (src, target) =>
      ({
        type: 'font',
        key: target,
        param: {value: {...fonts[src], ...sideCartOverride[target]}},
      } as unknown as FontStyleParam)
  );
}

function getMappedMiniCartNumbersParams(miniCartFonts: {[key: string]: FontStyleParamData}) {
  const numbersParams = {
    cornerRadius: ['goToCartButtonCornerRadius'],
    borderWidth: ['goToCartButtonBorderWidth'],
  };

  const numbers = {
    cornerRadius: parseInt(miniCartFonts?.cartWidgetButton_cornersRadius?.value ?? '0', 10),
    borderWidth: parseInt(miniCartFonts?.cartWidgetButton_borderSize?.value ?? '0', 10),
  };

  return mapSourceToTargets(numbersParams, (src, target) => ({
    type: 'number',
    key: target,
    param: {value: numbers[src]},
  }));
}

function getColorStyleParams(key: string, param: ColorParam): ColorStyleParam {
  return getStyleParams<ColorStyleParam>('color', key, mapEcomColorToSdkColor(param));
}

function getBooleanStyleParams(key: string, param: BooleanStyleParam['param']['value']): BooleanStyleParam {
  return getStyleParams<BooleanStyleParam>('boolean', key, {value: param});
}

function getFontStyleParams(key: string, param: FontStyleParam['param']['value']): FontStyleParam {
  return getStyleParams<FontStyleParam>('font', key, {value: {...param, fontStyleParam: true, preset: 'Custom'}});
}

function getNumberStyleParams(key: string, param: NumericStyleParam['param']['value']): NumericStyleParam {
  return getStyleParams<NumericStyleParam>('number', key, {value: param});
}

function getStyleParams<T1 extends StyleParam>(type: T1['type'], key: string, param: T1['param']) {
  return {
    type,
    key,
    param,
  };
}

function mapEcomColorToSdkColor(param: ColorParam): ColorStyleParam['param'] {
  // eslint-disable-next-line prefer-named-capture-group
  const opacity = param.value.includes('rgba') ? Number(param.value.replace(/^.*,(.+)\)/, '$1')) : 1;
  return {
    value: param.themeName
      ? {
          color: {value: 'val', name: param.themeName},
          opacity,
        }
      : {
          color: false,
          rgba: param.value,
          opacity,
        },
  };
}

export function mapColorParams(styleParams: EcomStyleParams): ColorStyleParam[] {
  const styleParamsMap = styleParams.thankYouPage?.colorParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap)
    .filter(([_, value]) => value?.value)
    .map(([key, value]) =>
      getColorStyleParams(
        ThankYouPageStyleParams.ColorParamNames[key as keyof (typeof ThankYouPageStyleParams)['ColorParamNames']],
        value
      )
    );
}

export function mapBooleanParams(styleParams: EcomStyleParams): BooleanStyleParam[] {
  const styleParamsMap = styleParams.thankYouPage?.booleanParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap)
    .filter(([_, value]) => value !== null && value !== undefined)
    .map(([key, value]) =>
      getBooleanStyleParams(
        ThankYouPageStyleParams.BooleanParamNames[key as keyof (typeof ThankYouPageStyleParams)['BooleanParamNames']],
        value
      )
    );
}

export function mapFontParams(styleParams: EcomStyleParams): FontStyleParam[] {
  const styleParamsMap = styleParams.thankYouPage?.fontParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap)
    .filter(([_, value]) => value?.family)
    .map(([key, value]) =>
      getFontStyleParams(
        ThankYouPageStyleParams.FontParamNames[key as keyof (typeof ThankYouPageStyleParams)['FontParamNames']],
        value
      )
    );
}

export function mapTextParams(
  styleParams: EcomStyleParams
): {key: string; value: TPAPublicDataValue; scope: TPAPublicDataScope}[] {
  const styleParamsMap = styleParams.thankYouPage?.textsParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap)
    .filter(([_, value]) => value)
    .map(([key, value]) => ({
      key: ThankYouPageStyleParams.TextParamNames[key as keyof (typeof ThankYouPageStyleParams)['TextParamNames']],
      value,
      scope: 'APP',
    }));
}

export function mapNumberParams(styleParams: EcomStyleParams): NumericStyleParam[] {
  const styleParamsMap = styleParams.thankYouPage?.numberParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap)
    .filter(([_, value]) => typeof value === 'number')
    .map(([key, value]) =>
      getNumberStyleParams(
        ThankYouPageStyleParams.NumberParamNames[key as keyof (typeof ThankYouPageStyleParams)['NumberParamNames']],
        value
      )
    );
}

async function migrateMiniCartSettings(sdk: {
  getComponent: (widgetId: string) => Promise<ComponentClientSpecMapEntry>;
  getCompRef: (component: ComponentClientSpecMapEntry) => Promise<ComponentRef>;
  getStyleParams: (ref: ComponentRef) => Promise<EditorStyleParams>;
  getSiteTextPresets: (ref: ComponentRef) => Promise<SiteTextPresets>;
  setStyleParams: (ref: ComponentRef, styleParams: StyleParam[]) => Promise<void>;
  getTexts: (ref: ComponentRef) => Promise<TPAPublicData>;
  setTexts: (options: {
    compRef: ComponentRef;
    scope: TPAPublicDataScope;
    key: string;
    value: TPAPublicDataValue;
  }) => Promise<void>;
  getAppSettingsByExternalId: (externalId: string) => Promise<{[key: string]: {[subKey: string]: TPAPublicDataValue}}>;
  isMultilingualEnabled: () => Promise<boolean>;
}) {
  const cartIconComp = await sdk.getComponent(StoresWidgetID.CART_ICON);
  if (!cartIconComp) {
    return;
  }
  const cartIconCompRef = await sdk.getCompRef(cartIconComp);
  if (!cartIconCompRef) {
    return;
  }

  const sideCartComp = await sdk.getComponent(StoresWidgetID.SIDE_CART);
  if (!sideCartComp) {
    return;
  }
  const sideCartCompRef = await sdk.getCompRef(sideCartComp);
  if (!sideCartCompRef) {
    return;
  }

  const miniCartStyleParams = await sdk.getStyleParams(cartIconCompRef);
  const miniCartTextPresets = await sdk.getSiteTextPresets(cartIconCompRef);

  const colorParams = getMappedMiniCartColorParams(miniCartStyleParams?.colors);
  const fontParams = getMappedMiniCartFontParams(miniCartStyleParams?.fonts, miniCartTextPresets);
  const booleanParams = getMappedMiniCartBooleanParams();
  const numberParams = getMappedMiniCartNumbersParams(miniCartStyleParams?.fonts);

  await sdk.setStyleParams(sideCartCompRef, [...colorParams, ...fontParams, ...booleanParams, ...numberParams]);

  const miniCartTexts = await sdk.getTexts(cartIconCompRef);

  const setTextKeys = async (toSet: {[key: string]: TPAPublicDataValue}) => {
    const setTextPromises = Object.entries(toSet)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => {
        return sdk.setTexts({compRef: sideCartCompRef, key, value, scope: 'COMPONENT'});
      });

    return Promise.all(setTextPromises);
  };

  const miniCartStrings = {
    CART_TITLE: miniCartTexts.APP?.MINI_CART_HEADER_TEXT,
    CART_EMPTY_CART: miniCartTexts.APP?.MINI_CART_EMPTY_CART,
    SIDE_CART_CART_BUTTON: miniCartTexts.APP?.MINI_CART_BUTTON_TEXT,
  };

  await setTextKeys(miniCartStrings);

  await sdk.setTexts({compRef: sideCartCompRef, key: 'DONT_SHOW_SIDE_CART_ON_MOBILE', value: true, scope: 'APP'});

  const isMultilingualEnabled = await sdk.isMultilingualEnabled();

  if (!isMultilingualEnabled) {
    return;
  }
  const mlKeys = await sdk.getAppSettingsByExternalId(cartIconComp.referenceId);
  const languageKeys = Object.keys(mlKeys).filter((key) => key.startsWith('multilingual_'));
  const languagePromises = languageKeys.map((languageKey: string) => {
    const language = languageKey.split('_')[1];
    const miniCartKeys = mlKeys[languageKey];

    const miniCartMap = {
      [toMultilingualKey('CART_TITLE', language)]: miniCartKeys?.MINI_CART_HEADER_TEXT,
      [toMultilingualKey('CART_EMPTY_CART', language)]: miniCartKeys?.MINI_CART_EMPTY_CART,
      [toMultilingualKey('SIDE_CART_CART_BUTTON', language)]: miniCartKeys?.MINI_CART_BUTTON_TEXT,
    };
    return setTextKeys(miniCartMap);
  });

  await Promise.all(languagePromises);
}

function toMultilingualKey(key: string, language: string) {
  return `${key}▶︎l:${language}`;
}
