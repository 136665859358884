import {WIX_ECOM} from '@wix/app-definition-ids';
import {PageMap, StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EditorSDK} from '@wix/platform-editor-sdk';

export const SideCartControllerName = 'sideCartLightboxController';
export const createLightbox = async (
  sdk: EditorSDK,
  managingAppDefId: string,
  isResponsive: boolean = false,
  t: (key: string) => string
) => {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;

  const options = {
    title: t('pages_panel_ecomPlatform_sideCart'),
    hideTitle: true,
    shouldNavigateToPage: false,
    controllerType: SideCartControllerName,
    popupRole: PageMap.SIDE_CART,
    definition: {
      data: {
        tpaApplicationId,
        tpaPageId: PageMap.SIDE_CART,
        managingAppDefId,
        appDefinitionId: WIX_ECOM,
        ...(!isResponsive
          ? {
              pageBackgrounds: {
                desktop: {
                  custom: true,
                  ref: {
                    type: 'BackgroundMedia',
                    color: '{color_15}',
                    colorOpacity: 0.3,
                    alignType: 'top',
                    fittingType: 'fill',
                    scrollType: 'fixed',
                  },
                  isPreset: true,
                },
                mobile: {
                  custom: true,
                  ref: {
                    type: 'BackgroundMedia',
                    color: '{color_15}',
                    colorOpacity: 0.3,
                    alignType: 'top',
                    fittingType: 'fill',
                    scrollType: 'fixed',
                  },
                  isPreset: true,
                },
              },
            }
          : {}),
      },
      components: [
        {
          type: 'Container',
          components: [
            {
              type: 'Component',
              skin: 'wysiwyg.viewer.skins.TPASectionSkin',
              layout: {
                width: 420,
                height: 815,
                x: 0,
                y: 0,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
              ...(isResponsive
                ? {
                    layouts: {
                      containerLayout: {},
                      componentLayout: {
                        hidden: false,
                        height: {
                          type: 'auto',
                        },
                        type: 'ComponentLayout',
                        width: {
                          type: 'percentage',
                          value: 100,
                        },
                      },
                      itemLayout: {
                        alignSelf: 'center',
                        margins: {
                          left: {
                            type: 'percentage',
                            value: 0,
                          },
                          right: {
                            type: 'percentage',
                            value: 0,
                          },
                          top: {
                            type: 'percentage',
                            value: 0,
                          },
                          bottom: {
                            type: 'percentage',
                            value: 0,
                          },
                        },
                        justifySelf: 'center',
                        type: 'FixedItemLayout',
                      },
                      type: 'SingleLayoutData',
                    },
                  }
                : {}),
              componentType: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
              data: {
                type: 'TPAMultiSection',
                applicationId: tpaApplicationId.toString(),
                appDefinitionId: WIX_ECOM,
                widgetId: StoresWidgetID.SIDE_CART,
              },
            },
          ],
          skin: 'wysiwyg.viewer.skins.area.RectangleArea',
          layout: {
            width: 420,
            x: 0,
            y: 0,
          },
          ...(isResponsive
            ? {
                layouts: {
                  containerLayout: {},
                  componentLayout: {
                    minHeight: {
                      type: 'px',
                      value: 100,
                    },
                    hidden: false,
                    height: {
                      type: 'percentage',
                      value: 100,
                    },
                    minWidth: {
                      type: 'px',
                      value: 0,
                    },
                    type: 'ComponentLayout',
                    width: {
                      type: 'px',
                      value: 420,
                    },
                    maxWidth: {
                      type: 'px',
                      value: 99999,
                    },
                  },
                  itemLayout: {
                    alignSelf: 'stretch',
                    margins: {
                      left: {
                        type: 'px',
                        value: 0,
                      },
                      right: {
                        type: 'px',
                        value: 0,
                      },
                      top: {
                        type: 'px',
                        value: 0,
                      },
                      bottom: {
                        type: 'px',
                        value: 0,
                      },
                    },
                    justifySelf: 'end',
                    type: 'FixedItemLayout',
                  },
                  type: 'SingleLayoutData',
                  metaData: {
                    isHidden: false,
                    isPreset: false,
                  },
                },
              }
            : {}),
          componentType: 'wysiwyg.viewer.components.PopupContainer',
          props: {
            type: 'PopupContainerProperties',
            alignmentType: 'fullHeight',
            horizontalAlignment: 'right',
            verticalAlignment: 'top',
            horizontalOffset: 0,
            verticalOffset: 0,
          },
          style: {
            ...(isResponsive
              ? {
                  type: 'ComponentStyle',
                  style: {
                    propertiesOverride: {},
                    properties: {
                      'alpha-bg': '1',
                      bg: 'color_11',
                    },
                    propertiesSource: {
                      'alpha-bg': 'value',
                      bg: 'theme',
                    },
                    groups: {},
                  },
                  componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                  styleType: 'custom',
                  skin: 'wysiwyg.viewer.skins.screenwidthcontainer.ResponsivePopupContainerWithColorBG',
                }
              : {}),
          },
          ...(!isResponsive
            ? {
                design: {
                  background: {
                    colorLayers: [
                      {
                        fill: {
                          type: 'SolidColor',
                          color: 'color_11',
                        },
                        opacity: 1,
                        type: 'SolidColorLayer',
                      },
                    ],
                  },
                },
              }
            : {}),
        },
      ],
      style: {
        ...(isResponsive
          ? {
              styleType: 'custom',
              style: {
                properties: {
                  'alpha-bg': '0.3',
                  bg: 'color_15',
                },
                propertiesSource: {
                  'alpha-bg': 'value',
                  bg: 'theme',
                },
                groups: {},
              },
              type: 'ComponentStyle',
              skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
            }
          : {}),
      },
    },
  } as any;

  const sideCartRef = await sdk.document.pages.popupPages.addConnected('', options);
  await (sdk.document.pages.popupPages as any).updateBehavior('', {
    popupRef: sideCartRef,
    shouldNavigateToPage: false,
  });

  return sideCartRef;
};
